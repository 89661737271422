import React, {useEffect, useState} from 'react';
import { checkCompletion, markComplete } from '../../../helpers/DRY_Functions.js';
import VsCodeSS from '../../../../images/VsCodeSS.PNG';

export default function InstallVsCodeLesson({memberData}) {

    const [status, setStatus] = useState(false);

    useEffect(() => {
        checkCompletion(memberData, "gettingStartedLesson2", setStatus);
    }, [memberData]);

    return (
        <div className="content">
            <h1>Installation of Visual Studio Code</h1>
            
            <p>
                Installing Visual Studio Code is simple and easy. Start with heading to the <a className="p-link" target="_blank" rel="noreferrer" href="https://code.visualstudio.com/download">Visual Studio Code Download Page</a>.
            </p>

            <p>
                Below are circled examples of the choices you can pick from. You'll want to choose 
                the version that matches your operating system. Click to download the installation file
                and run it to install Visual Studio Code on your computer.
                <img src={VsCodeSS} alt="A screenshot of the Visual Studio Code download page." />
            </p>

            <p>
                To avoid any issues, make sure you restart your computer after installation of Visual Studio Code. 
                If you've got Visual Studio Code installed, we can proceed to the next lesson.
            </p>

            {status === false ? (
                <>
                    <button id="gettingStartedLesson2" onClick={markComplete}>
                        Mark Complete
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </button>
                </>
            ) : (
                <>
                    <div className="complete-notice">
                        Completed!
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </div>
                </>
            )}
        </div>
    )
}