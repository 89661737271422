import React from 'react';
import '../../gettingstarted.css';
import InstallVsCodeLesson from '../../lessons/02_InstallVsCode_Lesson';
import CourseNavigation from '../../../../course-navigation/CourseNavigation';


export default function InstallVsCodePage() {

    const memberData = JSON.parse(localStorage.getItem("memberData"));


    return (
        <div className="gs-container">
            <div className="content">
                <div>
                    <h1>Getting Started</h1>
                </div>
                <div className="gs-lessons">
                    <div className="menu">
                        <h2>Course Track</h2>
                        <ul className="menu-track">
                            <CourseNavigation memberData={memberData}/>
                        </ul>
                    </div>

                    <div className="lesson-container">
                        <InstallVsCodeLesson memberData={memberData}/>
                    </div>
                </div>
            </div>
        </div>
    )
}
