import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { checkCompletion, markComplete } from '../../../helpers/DRY_Functions.js';

export default function ConclusionLesson({memberData}) {

    const [status, setStatus] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        checkCompletion(memberData, "gettingStartedLesson5", setStatus);
    }, [memberData]);

    const handleRedirectToDashboard = () => {
        navigate("/dashboard");
    };

    return (
        <div className="content">
            <h1>Conclusion</h1>
            
            <p>
                You now have your development environment set up! You'll tinker with it over time and add new things
                to it especially when you begin to learn about what Git/Github is. But let's save that for another time... 
                right now, you deserve to celebrate this win! As we move forward with learning code, you'll come to understand 
                the flow of using an IDE and how smooth it helps to make everything you do.
            </p>
            <p>
                This concludes the Getting Started section, and now you're ready to gets hands-on with writing code.
                Head back to your dashboard to find the other courses that are available to you.
            </p>
            <p>
                Until next time!
            </p>
            
            {status === false ? (
                <>
                    <button id="gettingStartedLesson5" onClick={markComplete}>
                        Mark Complete
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </button>
                </>
            ) : (
                <>
                    <div className="complete-notice">
                        Completed!
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </div>
                    <button onClick={handleRedirectToDashboard}>Dashboard</button>
                </>
            )}
        </div>
    )
}