import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/navbar/navbar.css';

export default function Navbar({memberData}) {

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate("/");
    }
    return (
        <>
            <nav className="navbar">
                <div onClick={handleRedirect} className="brand">
                        <svg xmlns="http://www.w3.org/2000/svg" className="brand-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 9l3 3-3 3m5 0h3M5 20h14a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                        </svg>
                        Devolution
                </div>

                <div className="links">
                    {window.memberstack.isAuthenticated === true ? (
                        <a href="/dashboard">Dashboard</a>
                    ) : (null)}
                    <a href="/about">About</a>
                    <a href="/">Contact</a>
                    <a href="/">Pricing</a>
                </div>
                
                {window.memberstack.information.email !== undefined ? 
                    <div className="nav-btns">
                        <a href="#/ms/profile">
                            <button className="account-btn">
                                My Account 
                                <svg xmlns="http://www.w3.org/2000/svg" className="account-btn-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                                </svg>
                            </button>
                        </a> 
                    </div>
                : 
                    <div className="nav-btns">
                        <a href="#/ms/signup/61cfacc5a578fb0004a9595ap">
                            <button className="account-btn">
                                Sign Up
                                <svg xmlns="http://www.w3.org/2000/svg" className="account-btn-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14" />
                                </svg>
                            </button>
                        </a>
                        <a href="#/ms/login">
                            <button className="login-account-btn">
                                Login
                                <svg xmlns="http://www.w3.org/2000/svg" className="login-account-btn-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
                                </svg>
                            </button>
                        </a>
                    </div>
                }
            </nav>
        </>
    )
}
