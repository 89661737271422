// promise-based wait function
export const wait = (ms) => 
        Promise.resolve(resolve => setTimeout(resolve, ms));

export const checkCompletion = (memberData, lesson, callback) => 
        memberData[lesson] === "complete" ? callback(true) : callback(false);

// marks lessons complete
export const markComplete = e => {
        window.MemberStack.onReady.then(member => {
            let courseData = {
                [e.target.id]: "complete",
            }
            
            member.updateMetaData(courseData)
            wait(2000).then(member.getMetaData().then(data => localStorage.setItem('memberData', JSON.stringify(data))));
            wait(4000).then(member.getMetaData().then(data => console.log("Updated Data", data)));
            wait(2000).then(setTimeout(() => {
                window.location.reload();
            }, 2000));
        });
};