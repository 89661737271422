import React, {useEffect, useState} from 'react';
import { checkCompletion, markComplete } from '../../../helpers/DRY_Functions.js';

export default function HowToUseVsCodeLesson({memberData}) {

    const [status, setStatus] = useState(false);

    useEffect(() => {
        checkCompletion(memberData, "gettingStartedLesson3", setStatus);
    }, [memberData]);

    return (
        <div className="content">
            <h1>[Video ] - How to Use VsCode</h1>
            
            <p>
                VsCode comes packed with a lot of useful features. 
                Check out the quick tutorial below from <a className="p-link" href="https://www.youtube.com/channel/UC4JX40jDee_tINbkjycV4Sg">Tech With Tim</a> on YouTube.
                You <strong>do not</strong> have to memorize everything or fully conceptualize how everything in VsCode works.
                This is just to help you familiarize yourself with the IDE. 
            </p>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/ORrELERGIHs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

            {status === false ? (
                <>
                    <button id="gettingStartedLesson3" onClick={markComplete}>
                        Mark Complete
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </button>
                </>
            ) : (
                <>
                    <div className="complete-notice">
                        Completed!
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </div>
                </>
            )}
        </div>
    )
}