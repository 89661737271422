import React, {useEffect} from 'react';
import { useNavigate } from 'react-router-dom';

export default function HTMLRoutes() {

    const navigate = useNavigate();
    const handleRedirect = (e) => { navigate("/" + e.target.getAttribute("name")) }
    const memberData = JSON.parse(localStorage.getItem("memberData"));
    
    // Checks current path and highlights the matching lesson
    const checkCurrentExactPath = () => {
        const listItems = document.querySelectorAll('.li-item');
        const currentPath = window.location.pathname.slice(1);
        for(let item of listItems) {
            if(item.getAttribute("name") === currentPath) {
                item.classList.add("active");
                break;
            };
        };
    };

    useEffect(() => {
        checkCurrentExactPath();
    }, []);


    const handleTrackCompleteSvg = (lesson) => {
        if(memberData[lesson]) {
            if(memberData[lesson] === "complete") {
                return (
                    <>
                        {checkCurrentExactPath()}
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </>
                )
            } else {
                return (
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" className="not-completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                    </>
                )
            }
        } else {
            return (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="not-completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                </>
            )
        }
    }

    return (
        <>
            <div onClick={handleRedirect} name={"html/intro"} className="li-item">
                <li name={"html/intro"}>Introduction: What is HTML?</li>
                {handleTrackCompleteSvg("htmlLesson1")}
            </div>
            <div onClick={handleRedirect} name={"html/document-structure"} className="li-item">
                <li name={"html/document-structure"}>HTML Document Structure</li>
                {handleTrackCompleteSvg("htmlLesson2")}
            </div>
        </>
    )
}