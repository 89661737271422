import React from 'react';
import GettingStartedRoutes from './GettingStartedRoutes.jsx';
import HTMLRoutes from './HTMLRoutes.jsx';


export default function CourseNavigation({memberData}) {

    const currentPath = (path) => {
        return window.location.pathname.split('/').includes(path)
    }

    return (
        <>
            {currentPath("getting-started") ? (<GettingStartedRoutes memberdata={memberData} />) : (null)}
            {currentPath("html") ? (<HTMLRoutes memberdata={memberData} />) : (null)}
        </>
    )
}
