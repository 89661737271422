import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function CourseCard({color, faIcon, title, motto, path, iconSize}) {

    const navigate = useNavigate();

    const handleRedirect = () => {
        navigate(path);
    }

    return (
        <div onClick={handleRedirect} style={{backgroundColor: color}} className="course-card">
            <div className="top">
                <div className="white-circle">
                    <i style={{fontSize: iconSize}} className={faIcon}></i>
                </div>
                <h4>{title}</h4>
            </div>
            <div className="bottom">
                <em>{motto}</em>
            </div>
        </div>
    )
}
