import React, { useEffect, useState } from 'react';

export default function HtmlLanding() {
    
    const [lessonText, setLessonText] = useState("");
    const [audioPlaying, setAudioPlaying] = useState(false);
    
    const msg = new SpeechSynthesisUtterance(lessonText);
    msg.voice = window.speechSynthesis.getVoices()[5];
    
    
    useEffect(() => {
        let pText = "";
        document.querySelectorAll("p").forEach(el => {
            pText += el.innerText + " ";
        });
        setLessonText(pText);
    }, []);

    const playOrEnd = () => {
        if(audioPlaying) {
            setAudioPlaying(false);
            window.speechSynthesis.cancel();
        } else {
            setAudioPlaying(true);
            window.speechSynthesis.speak(msg);
            msg.onend = () => {
                setAudioPlaying(false)
            }
        }
    }

    return (
        <div className="content">
            <h1>Overview: HTML Course</h1>
            <div className="speech-btn-container">
                {audioPlaying ? (
                    <div>
                        <i className="fas fa-assistive-listening-systems"></i>Hear this lesson
                        <button onClick={playOrEnd} className="text-to-speech-btn">
                            <i className="fas fa-pause"></i>
                        </button>
                    </div>
                ) : (
                    <div>
                        <i className="fas fa-assistive-listening-systems"></i>Hear this lesson
                        <button onClick={playOrEnd} className="text-to-speech-btn">
                            <i className="fas fa-play"></i>
                        </button>
                    </div>
                )}
            </div>
            <p>
                Welcome to HTML! In this course, you will learn about what HTML is and how it works with websites. 
                It is strongly advised to follow along in code. The benefit in doing this is known as "knowledge reinforcement" 
                which means you will more easily retain the information through actively applying it.
            </p>
            <p className="p-with-svg">
                Be sure to mark your lessons as
                <span className="complete-example">
                    Completed! 
                    <svg xmlns="http://www.w3.org/2000/svg" className="completed-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                </span>
                when finished with a module. This will help you keep track of your progress.
            </p>
            <p>That's all there is to it! When you're ready, please feel free to dive right in. Most importantly, have fun!</p>
            <p>Please use the <strong className="strong">Course Track</strong> to the left to access your lessons.</p>
        </div>
    )
}
