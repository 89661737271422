import React, {useEffect, useState} from 'react';
import { wait, checkCompletion, markComplete } from '../../../helpers/DRY_Functions.js';
import hljs from 'highlight.js';

export default function HTMLDocumentStructure({memberData}) {

    const [status, setStatus] = useState(false);

    useEffect(() => {
        checkCompletion(memberData, "htmlLesson2", setStatus);
        hljs.highlightAll();
    }, []);

    const renderCodeBlock = () => {
        return(
            <>
                <pre>
                    <code className="HTML">

{`<!DOCTYPE html>
<html>
    <head>
        <meta
            name="Devolution"
            content="Learn advanced Full Stack Web Development skills for as little as $15 a month!"
        />
        <title>HTML Document Structure</title>
    </head>
    <body>
            
    </body>
</html>
`}
                    </code>
                </pre>
                </>
        )
    }
    

    return (
        <div className="content">
            <h1>HTML Document Structure</h1>
            <p>
                HTML documents contain very useful information for your browser. Check out this template below:
            </p>
            {renderCodeBlock()}

            <p>
                This might look a little confusing if this is your first time looking at HTML. 
                Let's break it down line-by-line to get better understanding of what this is doing.
            </p>

                <ul>
                        <li>
                            <p>
                                <span className="code-line">{'<!DOCTYPE html>'}</span> is what we call a declaration.
                                It's a way for web browsers to recognize the type of document as being HTML.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span className="code-line">{'<html>'}</span> is what we call a root element.
                                It is the main element that wraps all other elements on a web page. 
                                The <span className="code-line">{'<html>'}</span> element is the root of an HTML document. It 
                                is accompanied by opening and closing tags <span className="code-line">{'<html></html>'}</span> which
                                help to define where the HTML begins and ends in the document.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span className="code-line">{'<head>'}</span> is the element which contains all information about 
                                a web page found in tags like <span className="code-line">{'<meta />'}</span> for metadata and <span className="code-line">{'<title>'}</span> for the title. <span className="code-line">{'<html>'}</span> also has opening and closing tags. 
                            </p>
                        </li>
                        <li>
                            <p>
                            <span className="code-line">{'<meta />'}</span> tags contain metadata that describe the content of a web page to search engines. When
                                you look up information in a search engine, the information you see on links to relevant websites like the title and description 
                                is all information found in each website's metadata.
                            </p>
                        </li>
                        <li>
                            <p>
                                <span className="code-line">{'<title>'}</span> is the element that contains the website's title. This shows up 
                                in your browser's tab for the website. Take a look, this website's tab in your browser has "Devolution" as the title!
                            </p>
                        </li>
                    </ul>
            

            {status === false ? (
                <>
                    <button id="htmlLesson2" onClick={markComplete}>
                        Mark Complete
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </button>
                </>
            ) : (
                <>
                    <div className="complete-notice">
                        Completed!
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </div>
                </>
            )}
        </div>
    )
}
