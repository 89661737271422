import React, {useEffect, useState} from 'react';
import { wait, checkCompletion, markComplete } from '../../../helpers/DRY_Functions.js';

export default function WhatIsHTML({memberData}) {

    const [status, setStatus] = useState(false);

    useEffect(() => {
        checkCompletion(memberData, "htmlLesson1", setStatus);
    }, []);

    return (
        <div className="content">
            <h1>Introduction: What is HTML?</h1>
            <p>
                HTML, or <strong>Hyper Text Markup Language</strong>, 
                is the standard markup language for building websites. It describes "pieces", or series of "pieces", 
                of a website page. Those "pieces" are called <span className="code-line">elements</span>. 
                Their job is to tell your browser exactly how to display content on your screen.</p>
            <p>
                Each element is built to help describe its purpose. A few examples of what elements are responsible for 
                would be like a collection of paragraphs, a list, a table with rows and columns, or we could use elements 
                with images and other forms of data. We will be diving further into elements later on in this course, but 
                the take-away here is that HTML is the skeleton of every single web page.
            </p>

            <p>In the next section, we'll be taking a look at what an HTML Document Structure looks like.</p>

            {status === false ? (
                <>
                    <button id="htmlLesson1" onClick={markComplete}>
                        Mark Complete
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </button>
                </>
            ) : (
                <>
                    <div className="complete-notice">
                        Completed!
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </div>
                </>
            )}
        </div>
    )
}
