import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../styles/landing/landing.css';

export default function Landing() {

    const navigate = useNavigate();

    useEffect(() => {
        if(window.memberstack.isAuthenticated) {
            navigate('/dashboard');
        }
    }, [navigate]); 


    return (
        <>
            <div id="landing-bg">
                <div className="landing-text-container">
                    <div className="landing-text">
                        <h1>Welcome to Devolution</h1>
                        <p>Become a professional Full Stack Developer <span>without killing your wallet!</span></p>
                        <a href="#/ms/signup/61cfacc5a578fb0004a9595ap">
                            <button>Create an Account</button>
                        </a>
                    </div>
                </div>
            </div>
        </>
    )
}
