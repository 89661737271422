import React from 'react';

export default function GettingStartedLanding() {
    return (
        <div className="content">
            <h1>Overview</h1>
            <p>
                Hi there! Welcome to Devolution. It's really exciting that you decided to begin your journey into
                code. We believe becoming a Professional Developer should be easily accessible and affordable to 
                everyone. Learning code should never come at the cost Bootcamps and Colleges charge ($20,000 - $40,000+ !!!), 
                and it should be taught in a way that isn't confusing and makes sense.
            </p>
            <p>
                We're dedicated to helping you take charge and control of your
                education. Ultimately, we want you to change your life for the better with a career in tech.
                It's a huge honor for us at Devolution because we get to be part of your journey without ever 
                becoming part of the problem with contracts, ISAs, or any other form of greedy and ridiculous 
                student debt.
            </p>
            <p>
                With that said, when you're ready, let's begin your journey! First thing we need to do is get your
                environment set up. This basically means we're going to prepare your computer's development environment 
                for writing and executing code. Don't worry! This doesn't change anything on your computer as it is now
                and is 100% safe!
            </p>
            
            <p>To begin, please use the <strong className="strong">Course Track</strong>  to the left to access your lessons.</p>
            <p className="p-with-svg">
                Be sure to mark your lessons as
                <span className="complete-example">
                    Completed! 
                    <svg xmlns="http://www.w3.org/2000/svg" className="completed-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                    </svg>
                </span>
                when finished with a module. This will help you keep track of your progress.
            </p>
        </div>
    )
}
