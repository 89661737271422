import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HTMLPage.css';
import HtmlLanding from './lessons/00_HtmlLanding';
import CourseNavigation from '../../course-navigation/CourseNavigation.jsx';

export default function HTMLPage() {

    const memberData = JSON.parse(localStorage.getItem("memberData"));

    return (
        <div className="html-container">
            <div className="content">
                <div>
                    <h1>HTML (Hyper Text Markup Language)</h1>
                </div>
                <div className="html-lessons">
                    <div className="menu">
                        <h2>Course Track</h2>
                        <ul className="menu-track">
                            <CourseNavigation memberData={memberData}/>
                        </ul>
                    </div>

                    <div className="lesson-container">
                        <HtmlLanding />
                    </div>
                </div>
            </div>
        </div>
    )
}
