import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../../HTMLPage.css';
import WhatIsHTML from '../../lessons/01_WhatIsHTML';
import CourseNavigation from '../../../../course-navigation/CourseNavigation.jsx';

export default function WhatIsHTMLPage() {

    const memberData = JSON.parse(localStorage.getItem("memberData"));
    const navigate = useNavigate();

    return (
        <div className="html-container">
            <div className="content">
                <div>
                    <h1>HTML (Hyper Text Markup Language)</h1>
                </div>
                <div className="html-lessons">
                    <div className="menu">
                        <h2>Course Track</h2>
                        <ul className="menu-track">
                            <CourseNavigation memberData={memberData}/>
                        </ul>
                    </div>

                    <div className="lesson-container">
                        <WhatIsHTML memberData={memberData} />
                    </div>
                </div>
            </div>
        </div>
    )
}
