import React, {useEffect, useState} from 'react';
import { checkCompletion, markComplete } from '../../../helpers/DRY_Functions.js';

export default function UsefulExtensionsLesson({memberData}) {

    const [status, setStatus] = useState(false);

    useEffect(() => {
        checkCompletion(memberData, "gettingStartedLesson4", setStatus);
    }, [memberData]);

    return (
        <div className="content">
            <h1>[Optional] - Useful Extensions</h1>
            
            <p>
                On the sidebar in your VsCode is where you can find your extensions tab. The types of extensions
                that you can apply to your editor are, for the most part, optional. They are also free, so that's really awesome! 
                We think these extensions listed below will prove useful and satisfying to have on your journey into code.
            </p>
            <p>
                The first extension is called <span className="code-line">Bracket Pair Colorizer</span> by CoenraadS. This 
                extension will color the brackets in your code, which look like this: <span className="code-line">{"{ }"}</span>.
                You may or may not have seen them before, but trust me when I tell you this: You want this extension! Code gets 
                nested between them with even more brackets, and coloring them really helps with keeping track of how deep 
                you are in nested brackets. Even if you don't know what they are now, just include it and thank us later!
            </p>
            <p>
                The second extension is called <span className="code-line">indent-rainbow</span> by oderwat. You're going to be
                doing a lot of indenting in your code, and like brackets it can sometimes be confusing to know how deep into 
                indentation you are in larger code blocks. However, they also line up with brackets! This goes very well 
                with <span className="code-line">Bracket Pair Colorizer</span>.
            </p>
            <p>
                The third extension is called <span className="code-line">Live Server</span>by Ritwick Dey. This is basically
                an extension that allows you to run a local server on your computer. It relies on an HTML file, and <strong>it would benefit you 
                greatly to have this extension when you get into the HTML course.</strong> 
            </p>
            <p>
                The forth extension is called <span className="code-line">Lorem ipsum</span> by Daniel Imms. This extension allows 
                you to generate and insert giberish into your documents. Why is this useful? Because when you're building a website,
                you sometimes need placeholder information to get an idea of how something is going to look with real, legible sentences.
                During development, however, wording isn't important when you're just testing components. It speeds up your development.
            </p>
            <p>
                The last extension is called <span className="code-line">Terminal</span> by Jun Han. This extension allows you to run 
                a terminal directly from your Visual Studio Code IDE. We <strong>highly recommend</strong> that you get this extension.
            </p>

            <p>
                There are WAY more extensions out there and you should definitely explore them. However, the ones listed above will put
                you at a great start with using your new IDE.
            </p>

            {status === false ? (
                <>
                    <button id="gettingStartedLesson4" onClick={markComplete}>
                        Mark Complete
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </button>
                </>
            ) : (
                <>
                    <div className="complete-notice">
                        Completed!
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </div>
                </>
            )}
        </div>
    )
}