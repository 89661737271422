import React, {useEffect, useState} from 'react';
import { checkCompletion, markComplete } from '../../../helpers/DRY_Functions.js';

export default function IntroToIDEsLesson({memberData}) {

    const [status, setStatus] = useState(false);

    useEffect(() => {
        checkCompletion(memberData, "gettingStartedLesson1", setStatus);
    }, [memberData]);

    return (
        <div className="content">
            <h1>Introduction to IDEs</h1>
            
            <p>
                IDE stands for Integrated Development Environment. An IDE is a type of software for building
                applications that combines common developer tools into a single Graphical User Interface (GUI).
                The perks of an IDE typically consists of syntax highlighting (piece of your code colored for 
                better readability), bug and error checking, compiling applications, running tests, and so much more.
            </p>
            <p>
                As developers, we use IDEs to make our lives easier and to code more efficiently. There are lots of IDEs
                to choose from, but the most popular one is called Visual Studio Code (VsCode) and it is free to use. 
                You're more than welcome to use any IDE that you're comfortable with, but we recommend using 
                Visual Studio Code as that will be the IDE used and referenced throughout all courses on Devolution. 
            </p>
            <p>
                In the next section, we will go over how to get Visual Studio Code installed on your machine.
            </p>

            {status === false ? (
                <>
                    <button id="gettingStartedLesson1" onClick={markComplete}>
                        Mark Complete
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </button>
                </>
            ) : (
                <>
                    <div className="complete-notice">
                        Completed!
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed-svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </div>
                </>
            )}
        </div>
    )
}