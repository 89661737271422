import React from 'react';
import { useNavigate } from 'react-router-dom';
import CourseNavigation from '../../course-navigation/CourseNavigation';
import './gettingstarted.css';
import GettingStartedLanding from './lessons/00_GettingStartedLanding';

export default function HTMLPage() {

    const memberData = JSON.parse(localStorage.getItem("memberData"));
    const navigate = useNavigate();

    const handleRedirect = (e) => {
        navigate("/" + e.target.getAttribute("name"))
        // console.log(e.target.getAttribute("name"))
    }

    const handleTrackCompleteSvg = (lesson) => {
        if(memberData[lesson]) {
            if(memberData[lesson] === "complete") {
                return (
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" className="completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z" />
                        </svg>
                    </>
                )
            } else {
                return (
                    <>
                        <svg xmlns="http://www.w3.org/2000/svg" className="not-completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                    </>
                )
            }
        } else {
            return (
                <>
                    <svg xmlns="http://www.w3.org/2000/svg" className="not-completed" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                    </svg>
                </>
            )
        }
    }

    return (
        <div className="gs-container">
            <div className="content">
                <div>
                    <h1>Getting Started</h1>
                </div>
                <div className="gs-lessons">
                    <div className="menu">
                        <h2>Course Track</h2>
                        <ul className="menu-track">
                            <CourseNavigation memberData={memberData} />
                        </ul>
                    </div>

                    <div className="lesson-container">
                        <GettingStartedLanding />
                    </div>
                </div>
            </div>
        </div>
    )
}
