import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/navbar/Navbar";
import Landing from "./components/landing/Landing.jsx";
import Dashboard from "./components/dashboard/Dashboard";

// getting started pages
import GettingStartedPage from './components/courses/gettingstarted/GettingStartedPage.jsx';
import IntroToIDEsPage from './components/courses/gettingstarted/pages/01_IntoToIDEs_Page/01_IntroToIDEs_Page';
import InstallVsCodePage from './components/courses/gettingstarted/pages/02_InstallVsCode/02_InstallVsCodePage';
import HowToUseVsCodePage from './components/courses/gettingstarted/pages/03_HowToUseVsCode/03_HowToUseVsCodePage';
import UsefulExtensionsPage from './components/courses/gettingstarted/pages//04_UsefulExtensions/04_UsefulExtensionsPage';

// html pages
import HTMLPage from "./components/courses/html/HTMLPage";
import WhatIsHTMLPage from './components/courses/html/pages/01_WhatIsHTML/WhatIsHTML';
import HTMLDocumentStructurePage from './components/courses/html/pages/02_HTMLDocumentStructure/HTMLDocumentStructure';
import ConclusionPage from './components/courses/gettingstarted/pages/05_Conclusion_Page/05_Conclusion_Page';


function App() {
    // setInterval(() => {
    //   const testSign = document.querySelector(".ms-test-mode");
    //   testSign.style.display = "none";
    //   if(testSign.style.display === "none") { clearInterval() }
    // }, 10);

    const [memberData, setMemberData] = useState({})
    useEffect(() => {
        window.MemberStack.onReady.then(member => {
            member.getMetaData()
            .then(data => {
              setMemberData(data)
              localStorage.setItem("memberData", JSON.stringify(data))
            })
        });
    }, []);

  return (
    <div className="container">
      <Router>
        <Navbar memberData={memberData}/>
        <Routes>
          <Route exact path="/" element={<Landing memberData={memberData} />}/>
          <Route exact path="/dashboard" element={<Dashboard memberData={memberData} />} />

          {/* Getting Started Routes */}
          <Route exact path="/getting-started/overview" element={<GettingStartedPage />} />
          <Route exact path="/getting-started/intro" element={<IntroToIDEsPage />} />
          <Route exact path="/getting-started/install-vscode" element={<InstallVsCodePage />} />
          <Route exact path="/getting-started/how-to-use-vscode" element={<HowToUseVsCodePage />} />
          <Route exact path="/getting-started/useful-extensions" element={<UsefulExtensionsPage />} />
          <Route exact path="/getting-started/conclusion" element={<ConclusionPage />} />

          {/* HTML Routes */}
          <Route exact path="/html/overview" element={<HTMLPage memberData={memberData} />} />
          <Route exact path="/html/intro" element={<WhatIsHTMLPage />} />
          <Route exact path="/html/document-structure" element={<HTMLDocumentStructurePage/>} />
        
        </Routes>
      </Router>

    </div>
  );
}

export default App;
